import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  bankTab: 'AXIS',
  newBranch: [],
  bankData: [],
  postbankStatement: [],
  bankTransaction: [],
  bankingSetting: [],
  load: true,
  adjustmentTab: '',
  invoiceListByIndex: [],
};

const slice = createSlice({
  name: 'banking',
  initialState,
  reducers: {
    setBankData(state, action) {
      state.bankData = action.payload;
    },
    setBankTab(state, action) {
      state.bankTab = action.payload;
    },
    setPostbankStatement(state, action) {
      state.postbankStatement = action.payload;
    },
    setBankTransaction(state, action) {
      state.bankTransaction = action.payload;
    },
    setBankingSetting(state, action) {
      state.bankingSetting = action.payload;
    },
    setLoad(state, action) {
      state.load = action.payload;
    },
    setadjustmentTab(state, action) {
      state.adjustmentTab = action.payload;
    },
    // setInvoiceList(state, action) {
    //   const { index, invoiceData } = action.payload; // Extract index and data
    //   state.invoiceListByIndex[index] = invoiceData; // Assign invoices to the corresponding index
    // },
    setInvoiceList(state, action) {
      // const { index, invoiceData } = action.payload; // Extract index and data
      state.invoiceListByIndex =  action.payload;  // Assign invoices to the corresponding index
    },
    clearInvoiceList(state, action) {
      const { index } = action.payload;
      delete state.invoiceListByIndex[index];
    },
  },
});

export default slice.reducer;

export const {
  setadjustmentTab,
  clearInvoiceList,
  setInvoiceList,
  setBankData,
  setLoad,
  setTab,
  setPostbankStatement,
  setBankTransaction,
  setBankingSetting,
  setBankTab,
} = slice.actions;

export const getBankTransaction = (a) => async (dispatch) => {
  try {
    await axios
      .get(`/get_Banktransaction?bank_Name=${a}`)
      .then((response) => dispatch(setBankTransaction(response.data?.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getBankStatementAll = (a) => async (dispatch) => {
  try {
    // dispatch(setLoad(true));
    await axios.get(`/get_bankStatementAll?bank_Name=${a}`).then((response) => {
      dispatch(setBankData(response.data?.data));
      dispatch(setLoad(false));
    });
  } catch (error) {
    dispatch(setLoad(false));
    return console.error(error.message);
  }
  return true;
};
export const getPostbankStatement = (id, ledId, type) => async (dispatch) => {
  try {
    await axios
      .post(`post_bankStatement?id=${id}&matched_Ledgerid=${ledId}&type=${type}`)
      .then((response) => dispatch(setPostbankStatement(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getBankingSetting = () => async (dispatch) => {
  try {
    await axios.get(`get_BankSettings`).then((response) => dispatch(setBankingSetting(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getInvoiceList = (type, ledgerId) => async (dispatch) => {
  try {
    const response = await axios.get(`/payment/invoices?type=${type}&ledger_ID=${ledgerId}`);
    // const invoiceData = response.data; // This contains the array of invoices
    dispatch(setInvoiceList(response.data));
    // dispatch(setInvoiceList({ index, invoiceData })); // Pass the array to the state
  } catch (error) {
    console.error('Failed to fetch invoices:', error);
  }
};

// /payment/invoices?type=PAY&ledger_ID=594
// /payment/invoices?type=PAY&ledger_ID=3380
