export const measurement = [
  { id: '1', label: 'BOU' },
  { id: '2', label: 'BGS' },
  { id: '3', label: 'BAL' },
  { id: '4', label: 'BOX' },
  { id: '5', label: 'BTL' },
  { id: '6', label: 'BKL' },
  { id: '7', label: 'BUN' },
  { id: '8', label: 'BDL' },
  { id: '9', label: 'CAN' },
  { id: '10', label: 'CRT' },
  { id: '11', label: 'CTN' },
  { id: '12', label: 'CMS' },
  { id: '13', label: 'CCM' },
  { id: '14', label: 'CBM' },
  { id: '15', label: 'DZN' },
  { id: '16', label: 'DRM' },
  { id: '17', label: 'GMS' },
  { id: '18', label: 'GGK' },
  { id: '19', label: 'GRS' },
  { id: '20', label: 'GYD' },
  { id: '21', label: 'KGS' },
  { id: '22', label: 'KLR' },
  { id: '23', label: 'KME' },
  { id: '24', label: 'LTR' },
  { id: '25', label: 'MTR' },
  { id: '26', label: 'MTS' },
  { id: '27', label: 'MLT' },
  { id: '28', label: 'NA' },
  { id: '29', label: 'NOS' },
  { id: '30', label: 'OTH' },
  { id: '31', label: 'PAC' },
  { id: '32', label: 'PRS' },
  { id: '33', label: 'PCS' },
  { id: '34', label: 'QTL' },
  { id: '35', label: 'ROL' },
  { id: '36', label: 'SET' },
  { id: '37', label: 'SQF' },
  { id: '38', label: 'SQM' },
  { id: '39', label: 'SQY' },
  { id: '40', label: 'TBS' },
  { id: '41', label: 'TGM' },
  { id: '42', label: 'THD' },
  { id: '43', label: 'TON' },
  { id: '44', label: 'TUB' },
  { id: '45', label: 'UGS' },
  { id: '46', label: 'UNT' },
  { id: '47', label: 'YDS' },
];
