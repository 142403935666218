import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  // job setting work
    jobData:[],
    jobEdit:[], 
    jobSetting:{},
    invoiceNumber:'',
    isLoading: true,
  //   jobSetting: {

  //   prefix: '',
  //   poNo: false,
  //   ebn: false,
  //   mot: false,

  //   exrate: false,
  //   col1: false,
  //   col2: false,
  //   col3: false,
  //   col4:false,
  //   // col1Name: '',
  //   // col2Name: '',
  //   // col3Name: '',
  //   job: false,
  //   tab:'new-jobs',
  //   disc: false,
  //   dis: false,
  //   transId: false,
  //   vehicleNo: false,

  //   field1: false,
  //   field2: false,
  //   field3: false,
  //   field4: false,
  //   field5: false,
  //   field6: false,
  //   field7: false,
  //   field8: false,
  //   field9: false,
  //   field10: false,
  //   field11: false,
  //   field12: false,
  //   field13: false,
  //   field14: false,
  //   field15: false,
  // },
};

const slice = createSlice({
  name: 'jobproject',
  initialState,
  reducers: {
    setjobProjectEdit(state, action) {
      state.jobEdit = action.payload;
    },
    setIsloading(state, action) {
      state.isLoading = action.payload;
    },
    setjobSetting(state, action) {
      state.jobSetting =action.payload;
    
    },
    setJobData(state, action) {
      state.jobData = action.payload;
    },
    setPdf(state, action) {
      state.pdf = action.payload;
    },
     setTab(state, action) {
    state.tab = action.payload;

  },
  setinvoiceNumber(state, action) {
    state.invoiceNumber = action.payload;
  }
  },
 
});

export const getjobProject = (sd, ed, n,r,s) => async (dispatch) => {
  try {
    dispatch(setIsloading(true));
    await axios.get(`/jobs/jobAll?start_date=${sd}&end_date=${ed}&pagination=${n}&records=${r}&search=${s}`).then((response) => dispatch(setJobData(response.data)));
    dispatch(setIsloading(false));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getjobProjectEdit = (id) => async (dispatch) => {
  try {
    await axios.get(`/jobs/job?ID=${id}`).then((response) => dispatch(setjobProjectEdit(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getjobSetting = () => async (dispatch) => {
  try {
    await axios.get('/jobs/jobsettings').then((response) => dispatch(setjobSetting(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getinvoiceNumber = () => async (dispatch) => {
  try{
  await axios.get('/jobs/invoiceNumber').then((response) => dispatch((setinvoiceNumber(response.data))));
} catch (error) {
  return console.error(error.message);
}
return true;
}

export default slice.reducer;

export const {
   setjobSetting,
   setJobData ,
   setIsloading,  
   setTab, 
   setjobProjectEdit,
   setPdf,
   setinvoiceNumber,
  } = slice.actions;


