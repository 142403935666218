import { useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import { useSelector, dispatch } from '../../../redux/store';
import { getNotification } from '../../../redux/slices/user';

const TextLine = () => {
  useEffect(() => {
    dispatch(getNotification());
  }, []);

  const state = useSelector((state) => state.user?.notification);

  const tds = state.some((e) => e?.notification);

  return (
    <Box
      sx={{
        display: 'flex',
        '@keyframes slideText': {
          from: {
            transform: 'translateX(-10%)',
            opacity: 1,
          },
          to: {
            transform: 'translateX(40%)',
            opacity: 1,
          },
        },
        animation: 'slideText 3s linear infinite',
      }}
    >
       < Typography
            sx={{
              color: 'black',
              display: 'inline-block',
              fontSize: '24px',
              fontWeight: 'bold',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
             
            }}
          >
           
            You need to pay your TDS.
          </Typography>

          {/* < Typography
            sx={{
              color: 'black',
              display: 'inline-block',
              fontSize: '24px',
              fontWeight: 'bold',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
             
            }}
          >
           
            You need to pay your TDS.
          </Typography> */}
        
      {/* {tds &&
        state.map((e) => (
          <Typography
            sx={{
              color: 'black',
              display: 'inline-block',
              fontSize: '24px',
              fontWeight: 'bold',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
             
            }}
          >
            {e.notification}
            { state.map((e)=> e.notification ) }
          </Typography>
        ))} */}
    </Box>
  );
};

export default TextLine;
